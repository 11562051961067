<template>
  <product title="Amazfit T-Rex 2"
    :swiper-data="swiperData"
    :slogan="slogan"
    price="1499"
    :colors="colors"
    :relatived-products="relativedProducts"
    :detail-images="detailImages"
    :param-images="paramImages"
    :video="video"
    buy-link="https://item.jd.com/100023000443.html"
  ></product>
</template>

<script>
import { CDN_URL } from '@/config'
import Product from '../Product'

export default {
  name: 't-rex',
  components: {
    Product
  },
  data () {
    return {
      slogan: '15 项军规认证 / -30℃ 低温模式 / 100 米防水 / 五星双频精准定位 / 实时记录轨迹导航',
      colors: [
        '午夜黑',
        '丛林绿',
        '星耀黑',
        '大地黄'
      ],
      swiperData: [
        [
          CDN_URL + '/images/product/t-rex2/swiper/3_pro_01.png',
          CDN_URL + '/images/product/t-rex2/swiper/3_pro_02.png',
          CDN_URL + '/images/product/t-rex2/swiper/3_pro_03.png',
          CDN_URL + '/images/product/t-rex2/swiper/3_pro_04.png',
          CDN_URL + '/images/product/t-rex2/swiper/3_pro_05.png'
        ],
        [
          CDN_URL + '/images/product/t-rex2/swiper/4_pro_01.png',
          CDN_URL + '/images/product/t-rex2/swiper/4_pro_02.png',
          CDN_URL + '/images/product/t-rex2/swiper/4_pro_03.png',
          CDN_URL + '/images/product/t-rex2/swiper/4_pro_04.png',
          CDN_URL + '/images/product/t-rex2/swiper/4_pro_05.png'
        ],
        [
          CDN_URL + '/images/product/t-rex2/swiper/1_pro_01.png',
          CDN_URL + '/images/product/t-rex2/swiper/1_pro_02.png',
          CDN_URL + '/images/product/t-rex2/swiper/1_pro_03.png',
          CDN_URL + '/images/product/t-rex2/swiper/1_pro_04.png',
          CDN_URL + '/images/product/t-rex2/swiper/1_pro_05.png'
        ],
        [
          CDN_URL + '/images/product/t-rex2/swiper/2_pro_01.png',
          CDN_URL + '/images/product/t-rex2/swiper/2_pro_02.png',
          CDN_URL + '/images/product/t-rex2/swiper/2_pro_03.png',
          CDN_URL + '/images/product/t-rex2/swiper/2_pro_04.png',
          CDN_URL + '/images/product/t-rex2/swiper/2_pro_05.png'
        ]
      ],
      relativedProducts: [
      ],
      detailImages: [
        CDN_URL + '/images/product/t-rex2/detail/1.jpg',
        CDN_URL + '/images/product/t-rex2/detail/2.jpg',
        CDN_URL + '/images/product/t-rex2/detail/3.jpg',
        CDN_URL + '/images/product/t-rex2/detail/4.jpg',
        CDN_URL + '/images/product/t-rex2/detail/5.jpg',
        CDN_URL + '/images/product/t-rex2/detail/6.jpg',
        CDN_URL + '/images/product/t-rex2/detail/7.jpg',
        CDN_URL + '/images/product/t-rex2/detail/8.jpg',
        CDN_URL + '/images/product/t-rex2/detail/9.jpg',
        CDN_URL + '/images/product/t-rex2/detail/10.jpg',
        CDN_URL + '/images/product/t-rex2/detail/11.jpg',
        CDN_URL + '/images/product/t-rex2/detail/12.jpg',
        CDN_URL + '/images/product/t-rex2/detail/13.jpg'
      ],
      paramImages: [
        CDN_URL + '/images/product/t-rex2/detail/14.jpg',
        CDN_URL + '/images/product/t-rex2/detail/15.jpg'
      ]
    }
  },
  methods: {
  }
}
</script>

<style>
</style>
